//@flow
import * as React from 'react'
import { orderBy } from 'lodash'
import { Flex, Box } from 'grid-styled/emotion'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import theme from '../../utils/theme'
import AgeGroups from '../../constants/ageGroups'
import EventSearchBox from './EventSearchBox'
import EventRefinementDropdown from './EventRefinementDropdown'
import EventRefinementDatepicker from './EventRefinementDatepicker'
import SlidingFilters, { MENU_STATES } from '../SlidingFilters'

import Viewport from '../layoutHelpers/Viewport'
import General from '../layoutHelpers/General'
import Button from '../ui/Button'

import filterIcon from './filter-icon.svg'

const Affix = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9997;
  ${theme.mq.lg} {
    top: 60px;
  }
`

const Container = styled.div`
  background: ${theme.colors.secondaryFill};
  padding: 15px 20px;
  height: 52px;
  ${theme.mq.lg} {
    width: 100%;
    display: flex;
    height: 80px;
    justify-content: center;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FilterSlider = styled.div`
  color: ${theme.colors.primaryFont};
  padding: 0px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > p {
    margin-bottom: 0;
    margin-right: 10px;
  }
`

const H1 = styled.h1`
  color: ${theme.colors.selection};
`

const Paragraph = styled.p`
  color: ${theme.colors.selection};
`

type State = {
  menuState: string,
}

class SearchFilterBar extends React.PureComponent<*, State> {
  state = {
    menuState: MENU_STATES.CLOSED,
  }
  toggleNav = () => {
    this.setState({
      menuState:
        this.state.menuState === MENU_STATES.OPEN
          ? MENU_STATES.CLOSED
          : MENU_STATES.OPEN,
    })
  }
  render() {
    const { menuState } = this.state
    return (
      <>
        <Viewport.Desktop>
          <Affix>
            <Container>
              <Flex flex="1 1 auto">
                <Box width={1 / 5} mr={'20px'} max-width={'264px'}>
                  <EventSearchBox placeholder="Search for Event" />
                </Box>
                <Box width={1 / 5} mr={'20px'} max-width={'264px'}>
                  <EventRefinementDatepicker
                    attribute={'startDateUnix'}
                    placeholder={'Start Date'}
                  />
                </Box>
                <Box width={1 / 5} mr={'20px'} max-width={'264px'}>
                  <EventRefinementDropdown
                    attribute={'type'}
                    placeholder={'Category'}
                    transformItems={items => orderBy(items, 'label')}
                    limit={30}
                  />
                </Box>
                <Box width={1 / 5} mr={'20px'} max-width={'264px'}>
                  <EventRefinementDropdown
                    attribute={'venue.name'}
                    placeholder="Venue"
                    transformItems={items => orderBy(items, 'label')}
                  />
                </Box>
                <Box width={1 / 5} max-width={'264px'}>
                  <EventRefinementDropdown
                    attribute={'ageGroup'}
                    placeholder="Age Group"
                    transformItems={items =>
                      orderBy(
                        items.map(i => ({
                          ...i,
                          originalLabel: i.label,
                          label: AgeGroups[i.label],
                        })),
                        'label'
                      )
                    }
                  />
                </Box>
              </Flex>
            </Container>
          </Affix>
        </Viewport.Desktop>
        <Viewport.Mobile>
          <Content>
            <Flex>
              <Box mt={['10px']} mr={['10px']}>
                <FilterSlider onClick={this.toggleNav}>
                  <Paragraph>filter events</Paragraph>
                  <General.Img src={filterIcon} />
                </FilterSlider>

                <SlidingFilters
                  menuState={menuState}
                  onCloseIconClick={this.toggleNav}
                >
                  <H1>Filter</H1>
                  <Flex flex="1 1 auto">
                    <Box width={1} mb={'20px'} max-width={'264px'}>
                      <EventSearchBox placeholder="Search for Events" />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={1} mb={'20px'} max-width={'264px'}>
                      <EventRefinementDatepicker
                        attribute={'startDateUnix'}
                        placeholder={'Start Date'}
                      />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={1} mb={'20px'} max-width={'264px'}>
                      <EventRefinementDropdown
                        attribute={'type'}
                        placeholder={'Category'}
                        transformItems={items => orderBy(items, 'label')}
                        limit={30}
                      />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={1} mb={'20px'} max-width={'264px'}>
                      <EventRefinementDropdown
                        attribute={'venue.name'}
                        placeholder="Venue"
                        transformItems={items => orderBy(items, 'label')}
                      />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width={1} mb={'20px'} max-width={'264px'}>
                      <EventRefinementDropdown
                        attribute={'ageGroup'}
                        placeholder="Age Group"
                        transformItems={items =>
                          orderBy(
                            items.map(i => ({
                              ...i,
                              originalLabel: i.label,
                              label: AgeGroups[i.label],
                            })),
                            'label'
                          )
                        }
                      />
                    </Box>
                  </Flex>
                  <Flex
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Box width={1} max-width={'264px'}>
                      <Button.Submit onClick={this.toggleNav}>
                        Close
                      </Button.Submit>
                    </Box>
                  </Flex>
                </SlidingFilters>
              </Box>
            </Flex>
          </Content>
        </Viewport.Mobile>
      </>
    )
  }
}

export default SearchFilterBar
