//@flow
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { InstantSearch } from 'react-instantsearch/dom'
import qs from 'qs'
import moment from 'moment'
import theme from '../utils/theme'
import Viewport from '../components/layoutHelpers/Viewport'

import Layout from '../components/Layout'
import SearchFilterBar from '../components/explorer/SearchFilterBar'
import EventInfiniteHits from '../components/explorer/EventsInfiniteHits'
import TrendingEvents from '../components/TrendingEvents'

type Props = {
  data: Object,
  history: Function,
  location: Object,
}
type State = {
  searchState: Object,
}

const SearchContainer = styled.div`
  margin: 0;
  ${theme.mq.lg} {
    margin: 65px 0;
  }
`

const updateAfter = 700
const createURL = state => `?${qs.stringify(state)}`
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : ''

const urlToSearchState = location => {
  const { range = {}, ...state } = qs.parse(
    location.search ? location.search.slice(1) : ''
  )
  const newState = {
    range: {
      startDateUnix: {
        min: moment().unix(),
        max: undefined,
      },
      ...range,
    },
    ...state,
  }

  return newState
}

class EventsPage extends React.PureComponent<Props, State> {
  debouncedSetState: any
  constructor(props: Props) {
    super(props)

    this.state = {
      searchState: props.location
        ? urlToSearchState(props.location)
        : createURL(),
    }
  }
  handleSearchStateChange = (searchState: Object) => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      )
    }, updateAfter)
    this.setState({ searchState })
  }
  render() {
    const { searchState } = this.state

    return (
      <Layout>
        <Helmet title={`Events`} />
        <Viewport.Desktop>
          <TrendingEvents />
        </Viewport.Desktop>
        <SearchContainer>
          <InstantSearch
            appId="CPJZ0DASZH"
            apiKey="77d6fb5c8deb12b8b95c5b0f21a1368d"
            indexName="EventCustomIndex"
            onSearchStateChange={this.handleSearchStateChange}
            searchState={searchState}
          >
            <SearchFilterBar />
            <EventInfiniteHits />
          </InstantSearch>
        </SearchContainer>
      </Layout>
    )
  }
}

export default EventsPage
