//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import { connectRefinementList } from 'react-instantsearch/connectors'
import ClickOutside from 'react-click-outside'

import SelectOption from '../ui/SelectOption'
import { FakeInput as Input } from '../ui/Input'
import Theme from '../../utils/theme'

const Container = styled.div`
  position: relative;
`

const Options = styled.div`
  flex: 1;
  background: ${Theme.colors.tertiaryFill};
  position: absolute;
  top: 43px;
  z-index: 999;
  width: 100%;
  user-select: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`

type State = {
  showOptions: boolean,
}

class EventRefinementDropdown extends React.PureComponent<*, State> {
  wrapperRef: ?HTMLElement
  state = {
    showOptions: false,
  }

  toggleOptions = (showOptions: boolean) => {
    this.setState({
      showOptions,
    })
  }

  handleClickOutside = e => {
    if (this.state.showOptions === true) {
      e.stopPropagation()
      this.toggleOptions(false)
    }
  }
  handleClick = value => {
    this.props.refine(value)
  }
  render() {
    const { items, placeholder } = this.props
    const { showOptions } = this.state

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <Container>
          <Input
            placeholder={
              <span>
                {placeholder} ({items.filter(i => i.isRefined === true).length})
              </span>
            }
            onClick={() => this.toggleOptions(!showOptions)}
          />
          {showOptions && (
            <Options>
              {items.map((option, i) => {
                return (
                  <SelectOption
                    key={i}
                    onClick={this.handleClick}
                    defaultSelected={option.isRefined}
                    label={option.label}
                    value={option.value}
                  />
                )
              })}
            </Options>
          )}
        </Container>
      </ClickOutside>
    )
  }
}

export default connectRefinementList(EventRefinementDropdown)
