//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import Theme from '../../utils/theme'
import { rhythm } from '../../utils/typography'
import { Flex, Box } from 'grid-styled/emotion'
import emptystateimage from '../../pages/partnership-page/trees.svg'

import SplitContainer from '../../components/ui/SplitContainer'

const { Paragraph: P } = SplitContainer

const Container = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 355px;
  padding: 0px 40px;
  ${Theme.mq.md} {
    width: 688px;
    margin: 80px auto;
  }
  ${Theme.mq.lg} {
    width: 941px;
  }
`

const Img = styled.img`
  width: 200px;
  ${Theme.mq.md} {
    width: 300px;
  }
`

const Exclamation = styled.h1`
  font-size: ${rhythm(1.5)};
  color: ${Theme.colors.selection};
  ${Theme.mq.md} {
    font-size: ${rhythm(2)};
  }
  ${Theme.mq.lg} {
    font-size: ${rhythm(3)};
  }
`

const A = styled.a`
  color: ${Theme.colors.selection};
  cursor: pointer;
`

export default () => {
  return (
    <Container>
      <Img src={emptystateimage} />
      <Exclamation>Well, I'll be damned</Exclamation>
      <P>
        We can’t find events that match your criteria. Try experimenting <br />
        with different filters and venues.
      </P>
      <P>
        If you event doesn’t exist yet on Park City Insider then <br />
        <A href="/add-event"> add your event </A>
        or <A href="/contact">make a suggestion</A> <br /> and we'll
        investigate.
      </P>
    </Container>
  )
}
