//@flow
import * as React from 'react'
import Input from '../ui/Input'
import { connectSearchBox } from 'react-instantsearch/connectors'
import { debounce } from 'lodash'

class EventSearchBox extends React.PureComponent<*, *> {
  handleChange = e => {
    this.refine(e.currentTarget.value)
  }
  refine = debounce(value => this.props.refine(value), 300)
  //   refine = this.props.refine;
  render() {
    const { currentRefinement, placeholder } = this.props
    return (
      <Input
        size="small"
        placeholder={placeholder}
        onChange={this.handleChange}
        defaultValue={currentRefinement && currentRefinement}
      />
    )
  }
}

export default connectSearchBox(EventSearchBox)
