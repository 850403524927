//@flow
import * as React from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ClickOutside from 'react-click-outside'
import Calendar from 'rc-calendar'
import { connectRange } from 'react-instantsearch/connectors'
import { FakeInput as Input } from '../ui/Input'
import Theme from '../../utils/theme'
import 'rc-calendar/assets/index.css'

const Container = styled.div`
  position: relative;
  & .rc-calendar-date-panel {
    background: ${Theme.colors.tertiaryFill};
  }
`

const Options = styled.div`
  flex: 1;
  background: ${Theme.colors.tertiaryFill};
  position: absolute;
  top: 44px;
  z-index: 999;
  user-select: none;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const calendarRootCss = css`
  width: 100%;
`

type State = {
  showOptions: boolean,
  min: ?number,
  max: ?number,
}

class EventDateRefinement extends React.Component<*, State> {
  state = {
    showOptions: false,
    min: null,
    max: null,
  }
  // constructor(props) {
  //   super(props);

  //   if (!props.currentRefinement.min) {
  //     props.refine({
  //       min: moment()
  //         .startOf('day')
  //         .unix(),
  //       max: undefined,
  //     });
  //   }
  // }
  toggleOptions = (showOptions: boolean) => {
    this.setState({
      showOptions,
    })
  }

  handleClickOutside = e => {
    if (this.state.showOptions === true) {
      e.stopPropagation()
      this.toggleOptions(false)
    }
  }
  handleChange = (date: moment /*, dateString: string*/) => {
    const { refine } = this.props
    if (!date) {
      return refine({ min: undefined, max: undefined })
    }

    refine({
      min: date.startOf('day').unix(),
      max: undefined,
    })
  }
  render() {
    const { currentRefinement, placeholder } = this.props
    const { showOptions } = this.state

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <Container showOptions={showOptions}>
          <Input
            placeholder={
              currentRefinement.min
                ? moment.unix(currentRefinement.min).format('LL')
                : placeholder
            }
            disabled
            onClick={() => this.toggleOptions(!showOptions)}
          />
          {currentRefinement.min && showOptions && (
            <Options>
              <Calendar
                className={calendarRootCss}
                onChange={this.handleChange}
                showDateInput={false}
                value={
                  currentRefinement.min
                    ? moment.unix(currentRefinement.min)
                    : moment()
                }
              />
            </Options>
          )}
        </Container>
      </ClickOutside>
    )
  }
}

export default connectRange(EventDateRefinement)
