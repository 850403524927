//@flow
import * as React from 'react'
import { connectInfiniteHits } from 'react-instantsearch/connectors'
import idx from 'idx'
import { groupBy, size } from 'lodash'
import { rhythm } from '../../utils/typography'
import moment from 'moment'
import styled from '@emotion/styled'
import Waypoint from 'react-waypoint'
import { sortBy } from 'lodash'

import Theme from '../../utils/theme'
import { EventCardRowVertical } from '../ui/EventCardRow'
import EventInfiniteHitsNoResults from './EventInfiniteHitsNoResults'

const Container = styled.div`
  max-width: 941px;
  margin: 0 auto;
  padding: 0 20px;
  ${Theme.mq.md} {
    padding: 0 40px;
  }
  ${Theme.mq.lg} {
    padding: 0;
  }
`

//TODO: Border color in theme
const DateSection = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${Theme.colors.primaryFill};
  ${Theme.mq.lg} {
    display: flex;
    flex-direction: row;
    width: 941px;
    padding: 44px 0;
  }
`

const DateGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
`

const DateHeaderSmall = styled.h5`
  margin: 0 0 5px 0;
  font-weight: 100;
  font-size: 1rem;
  color: ${Theme.colors.selection};
`

const DateHeader = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: ${Theme.colors.primaryFont};
  margin-bottom: ${rhythm(1)};
  margin-right: 12px;
`

const EventsItems = styled.div`
  flex: 1;
`

const groupEventsByDayAndSort = (events: Object[]) => {
  const days = groupBy(events, event => {
    return moment(event.startDate)
      .startOf('day')
      .format()
  })

  const sortedDays = Object.keys(days).sort((a, b) => {
    const am = moment(a)
    const bm = moment(b)
    if (am.isSame(bm)) {
      return 0
    }
    if (am.isBefore(bm)) {
      return -1
    }
    return 1
  })

  return { sortedDays, days }
}

class EventInfiniteHits extends React.PureComponent<*, *> {
  state = {
    sortedDays: [],
    days: {},
    hits: [],
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.hits !== state.hits) {
      const { sortedDays, days } = groupEventsByDayAndSort(
        idx(nextProps, p => p.hits) || []
      )

      sortedDays.forEach(day => {
        days[day] = sortBy(days[day], e => e.startDateUnix)
      })

      return { sortedDays, days, hits: nextProps.hits }
    }
    return null
  }
  render() {
    const { hasMore, refine } = this.props
    const { sortedDays, days } = this.state
    if (size(sortedDays) === 0) {
      return <EventInfiniteHitsNoResults />
    }
    return (
      <Container>
        {sortedDays.map((day, i) => {
          const dataSource = days[day]

          return (
            <DateSection key={i}>
              <DateGroup>
                <DateHeaderSmall>{moment(day).format('dddd')}</DateHeaderSmall>
                <DateHeader>{moment(day).format('MMM DD, YYYY')}</DateHeader>
              </DateGroup>
              <EventsItems>
                <EventCardRowVertical events={dataSource} />
              </EventsItems>
            </DateSection>
          )
        })}
        {hasMore && <Waypoint onEnter={refine} />}
      </Container>
    )
  }
}

export default connectInfiniteHits(EventInfiniteHits)
